.PageLayout {
    width: 100vw;
    height: 100px;
    margin-top: -50vh;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.TitleComponent {
    font-size: 28px;
    font-weight: 500;
    color: rgb(233, 213, 255);
    margin-bottom: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.LoggedInComponent {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin: 0px 15px;
}

.LoggedInComponentText {
    font-size: 20px;
    font-weight: 500;
    color: #e3ccfce3;
}

.LoggedInComponentTextBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    max-width: 300px;
    max-height: 55px;
}
.SingleReportType {
    background: #0000003d;
    display: flex;
    align-items: center;
    text-align: left;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.TitleComponent {
    font-size: 26px;
    font-weight: 500;
    color: rgb(233, 213, 255);
    margin-top: -5px;
    width: 100%;
    display: flex;
    align-items: center;
}

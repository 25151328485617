.AllReportsComponent {
    overflow-y: auto;
    height: 500px;
}

::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: #bcb9b9;
    border-radius: 5px;
    border: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background: #90caf9;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #83c4fa;
  }

.GroupingHeader {
    background: #0000003d;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    color: rgb(233, 213, 255);
    margin: 30px 15px 0px 10px;
    padding-left: 1%;
    width: 1140px;
    border-radius: 8px;
}

.TitleLoadingComponent {
    font-size: 28px;
    font-weight: 500;
    color: rgb(233, 213, 255);
    margin-bottom: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.LoginComponent {
    margin: 0px 30px;
}

.TitleLoginComponent {
    font-size: 28px;
    font-weight: 500;
    color: rgb(233, 213, 255);
    margin-bottom: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.ButtonGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
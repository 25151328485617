.Background {
  height: 100vh;
  background: rgb(101,0,121);
  background: -moz-linear-gradient(39deg, rgba(101,0,121,0.6) 0%, rgba(21,21,172,0.6) 47%, rgba(0,151,255,0.6) 100%);
  background: -webkit-linear-gradient(39deg, rgba(101,0,121,0.6) 0%, rgba(21,21,172,0.6) 47%, rgba(0,151,255,0.6) 100%);
  background: linear-gradient(39deg, rgba(101,0,121,0.6) 0%, rgba(21,21,172,0.6) 47%, rgba(0,151,255,0.6) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#650079",endColorstr="#0097ff",GradientType=1);
}

.CenterComponents{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
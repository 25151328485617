.ReportsTable {
  height: 50vh;
  margin-top: 50vh;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.TitleLoadingComponent {
  font-size: 28px;
  font-weight: 500;
  color: rgb(233, 213, 255);
  margin-top: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.LoadingComponent {
  height: 95%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ButtonsReport {
  height: 10%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 0px 13px;
}

.TitleLoadingComponent {
  font-size: 28px;
  font-weight: 500;
  color: rgb(233, 213, 255);
  margin-top: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.LoadingComponent {
  height: 95%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
